<template>
  <section id="master-helpdesk">
    <b-row>
      <b-col
        md="3"
        cols="12"
      >
        <b-card>
          <h4 class="text-center mb-1">
            Pertanyaan Pengguna
          </h4>
          <b-list-group class="mb-2">
            <b-list-group-item
              :active="userSelect === 'all'"
              class="d-flex justify-content-start align-items-center"
              @click="userFilter('all')"
            >
              Semua Pertanyaan
            </b-list-group-item>
            <b-list-group-item
              :active="userSelect === 'me'"
              class="d-flex justify-content-start align-items-center"
              @click="userFilter('me')"
            >
              Pertanyaan Saya
            </b-list-group-item>
          </b-list-group>
          <h4 class="mb-1 d-flex justify-content-between align-items-center">
            Kategori
            <b-button
              v-if="userData.group_id === 10"
              variant="outline-info"
              size="sm"
              @click="tambahCat()"
            >
              Tambah Kategori
            </b-button>
          </h4>
          <b-list-group class="mb-2">
            <b-list-group-item
              :active="category_id === ''"
              class="d-flex justify-content-start align-items-center"
              @click="catFilter('')"
            >
              Semua
            </b-list-group-item>
            <b-list-group-item
              v-for="(data, index) in referenceData"
              :key="index"
              :active="data.nama === category_id"
              class="d-flex justify-content-between align-items-center"
              @click="catFilter(data.nama)"
            >
              <div>
                {{ data.nama }}
              </div>
              <b-button
                v-if="userData.group_id === 10"
                variant="flat-warning"
                size="sm"
                @click="editCat(data)"
              >
                <feather-icon
                  icon="EditIcon"
                />
              </b-button>
            </b-list-group-item>
          </b-list-group>

          <h4 class="text-center mb-1">
            Pencarian
          </h4>
          <validation-observer ref="simpleRules">
            <b-form @submit.prevent="validationForm">
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    label="Kata Kunci"
                    label-for="vi-keyword"
                  >
                    <b-form-input
                      id="vi-keyword"
                      v-model="keyword"
                      required
                      name="keyword"
                      placeholder="Kata Kunci"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-1"
                >
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-50"
                  >
                    Cari
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    @click="searchReset"
                  >
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>

        <b-card
          no-body
          class="card-transaction"
        >
          <b-card-header>
            <b-card-title>Statistik Kategori</b-card-title>
          </b-card-header>

          <!-- body -->
          <b-card-body>
            <div
              v-for="(data, index) in stats.category"
              :key="index"
              class="transaction-item"
            >
              <b-media no-body>
                <b-media-body>
                  <h6 class="transaction-title">
                    {{ data.nama }}
                  </h6>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder text-success">
                {{ Number(data.total).toLocaleString() }}
              </div>
            </div>
          </b-card-body>
          <!--/ body -->
        </b-card>
      </b-col>

      <b-col
        md="9"
        cols="12"
      >
        <b-card
          v-if="stats.statistik"
          no-body
          class="card-statistics"
        >
          <b-card-header>
            <b-card-title>Statistik Umum</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col>
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="warning"
                    >
                      <feather-icon
                        size="24"
                        icon="InboxIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ Number(stats.statistik.ticket_open).toLocaleString() }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Tiket Aktif
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col>
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="info"
                    >
                      <feather-icon
                        size="24"
                        icon="CheckSquareIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ Number(stats.statistik.ticket_close).toLocaleString() }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Tiket Selesai
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col>
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="success"
                    >
                      <feather-icon
                        size="24"
                        icon="MailIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ Number(stats.statistik.total_ticket).toLocaleString() }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Total Tiket
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card>
          <div class="d-flex justify-content-between mb-1">
            <b-button
              v-if="userData.group_id > 99"
              class="btn btn-warning btn-md"
              @click.prevent="tambahTiket"
            >
              Buat Pertanyaan Baru
            </b-button>

            <b-button
              size="sm"
              variant="outline-primary"
              @click="toggleOrder"
            >
              <feather-icon
                :icon="orderToggleIcon"
                size="14"
                class="mr-25"
              /> Order
            </b-button>
          </div>
          <template v-if="alert">
            <b-alert
              :variant="alertDetail.variant"
              class="mt-2"
              show
            >
              <h4 class="alert-heading">
                {{ alertDetail.title }}
              </h4>
              <div
                v-if="alertDetail.variant === 'danger'"
                class="alert-body"
              >
                <ul v-if="typeof alertDetail.text === 'object'">
                  <li
                    v-for="(item, index) in alertDetail.text"
                    :key="index"
                  >
                    {{ index }}:
                    <span
                      v-for="(child, indexs) in item"
                      :key="indexs"
                    >
                      {{ child }}
                    </span>
                  </li>
                </ul>
                <template v-else>
                  {{ alertDetail.text }}
                </template>
              </div>
              <div
                v-else
                class="alert-body"
              >
                {{ alertDetail.text }}
              </div>
            </b-alert>
          </template>

          <b-list-group class="mb-2">
            <b-list-group-item
              v-for="dt in rows"
              :key="dt.helpdesk_id"
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between">
                <h6 class="mb-25">
                  <b-link :to="{name: 'pages-helpdesk-detail', params: { id: dt.helpdesk_id } }">
                    {{ dt.subject }}
                  </b-link>
                </h6>
                <div>
                  <small class="text-primary">{{ dt.modified_on | formatDate }}</small>
                  <b-badge
                    variant="primary"
                    pill
                    class="ml-50 badge-round"
                  >
                    {{ dt.reply_count }}
                  </b-badge>
                </div>
              </div>
              <div class="my-25">
                <b-badge
                  variant="success"
                  class="mr-50"
                >
                  {{ dt.category }}
                </b-badge>
                <b-badge
                  :variant="(dt.open) ? 'light-info' : 'light-secondary'"
                  class="mr-50"
                >
                  {{ (dt.open) ? 'open' : 'closed' }}
                </b-badge>
              </div>
              <b-card-text class="mb-1">
                {{ dt.author }} &middot; {{ dt.nama_instansi }}
              </b-card-text>
            </b-list-group-item>
          </b-list-group>

          <b-pagination
            v-model="selectPage"
            pills
            :total-rows="totalRecords"
            :per-page="serverParams.length"
            class="mb-0"
            @input="onPageChange"
          />

        </b-card>
      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-tambah-tiket"
      ref="modal-tambah-tiket"
      title="Buat Tiket Baru"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <template v-if="alert">
          <b-alert
            :variant="alertDetail.variant"
            class="mb-1"
            show
          >
            <h4 class="alert-heading">
              {{ alertDetail.title }}
            </h4>
            <div
              v-if="alertDetail.variant === 'danger'"
              class="alert-body"
            >
              <ul v-if="typeof alertDetail.text === 'object'">
                <li
                  v-for="(item, index) in alertDetail.text"
                  :key="index"
                >
                  {{ index }}:
                  <span
                    v-for="(child, indexs) in item"
                    :key="indexs"
                  >
                    {{ child }}
                  </span>
                </li>
              </ul>
              <template v-else>
                {{ alertDetail.text }}
              </template>
            </div>
            <div
              v-else
              class="alert-body"
            >
              {{ alertDetail.text }}
            </div>
          </b-alert>
        </template>
        <validation-observer ref="newTicket">
          <b-form @submit.prevent="checkTicket">
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Pilih Kategori"
                  label-for="vi-category"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Kategori"
                    rules="required"
                  >
                    <v-select
                      id="vi-category"
                      v-model="category"
                      required
                      name="category"
                      :options="referenceData"
                      placeholder="Kategori"
                      label="nama"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Judul"
                  label-for="vi-title"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Judul"
                    rules="required"
                  >
                    <b-form-input
                      id="vi-title"
                      v-model="title"
                      required
                      name="title"
                      placeholder="Judul Pertanyaan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Pertanyaan"
                  label-for="vi-desc"
                >
                  <quill-editor
                    id="vi-desc"
                    v-model="desc"
                    :options="editorOption"
                    class="mb-1"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mb-1"
              >
                <b-button
                  type="submit"
                  variant="primary"
                >
                  Kirim Pertanyaan
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-tambah-cat"
      ref="modal-tambah-cat"
      title="Kategori"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <template v-if="alert">
          <b-alert
            :variant="alertDetail.variant"
            class="mb-1"
            show
          >
            <h4 class="alert-heading">
              {{ alertDetail.title }}
            </h4>
            <div
              v-if="alertDetail.variant === 'danger'"
              class="alert-body"
            >
              <ul v-if="typeof alertDetail.text === 'object'">
                <li
                  v-for="(item, index) in alertDetail.text"
                  :key="index"
                >
                  {{ index }}:
                  <span
                    v-for="(child, indexs) in item"
                    :key="indexs"
                  >
                    {{ child }}
                  </span>
                </li>
              </ul>
              <template v-else>
                {{ alertDetail.text }}
              </template>
            </div>
            <div
              v-else
              class="alert-body"
            >
              {{ alertDetail.text }}
            </div>
          </b-alert>
        </template>
        <validation-observer ref="newCat">
          <b-form @submit.prevent="checkCat">
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Nama Kategori"
                  label-for="vi-title"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Kategori"
                    rules="required"
                  >
                    <b-form-input
                      id="vi-title"
                      v-model="cat.nama"
                      required
                      name="title"
                      placeholder="Nama Kategori"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mb-1"
              >
                <b-button
                  type="submit"
                  variant="primary"
                >
                  Simpan
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BForm, BRow, BButton, BCol, BCard, BFormGroup, BAlert, VBModal, BModal,
  BFormInput, BCardText, BLink, BBadge, BListGroup, BListGroupItem,
  BPagination, BCardBody, BCardHeader, BCardTitle, BMedia, BMediaBody, BMediaAside, BAvatar,
} from 'bootstrap-vue'

import { quillEditor } from 'vue-quill-editor'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BPagination,
    // BFormSelect,
    BCardText,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BBadge,
    BButton,
    BModal,
    BAlert,
    BCard,
    BRow,
    BCol,
    BLink,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
  },
  setup() {
    const toast = useToast()

    const editorOption = {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['clean', 'link', 'image'],
        ],
      },
      placeholder: 'Pertanyaan Anda',
    }

    return {
      toast,
      editorOption,
    }
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      isLoading: false,
      userData: getUserData(),
      referenceData: [],
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      cat: {
        nama: '',
        status: 1,
      },
      keyword: '',
      category: '',
      title: '',
      desc: '',
      instansi_id: '',
      category_id: '',
      rows: [],
      totalRecords: 0,
      selectPage: 1,
      order: 'desc',
      userSelect: 'all',
      serverParams: {
        sort_by: [
          {
            column: 'modified_on',
            asc_desc: 'DESC',
          },
        ],
        start: 0,
        length: 20,
        asc_desc: 'DESC',
      },
      stats: {
        category: [],
        statistik: {
          ticket_close: 0,
          ticket_open: 0,
          total_ticket: 0,
        },
      },
    }
  },
  computed: {
    orderToggleIcon() {
      return this.order === 'ASC' ? 'ChevronsUpIcon' : 'ChevronsDownIcon'
    },
  },
  mounted() {
    this.getReference()
  },
  methods: {
    toggleOrder() {
      this.order = (this.order === 'ASC') ? 'DESC' : 'ASC'
      this.serverParams.asc_desc = this.order
      this.onSortChange()
    },
    tambahTiket() {
      this.$refs['modal-tambah-tiket'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.updateParams({ keyword: this.keyword })
          this.loadItems()
        }
      })
    },
    searchReset() {
      this.keyword = ''
      this.updateParams({ keyword: '' })
      this.loadItems()
    },
    userFilter(val) {
      this.userSelect = val
      this.category_id = ''
      this.doFilter()
    },
    catFilter(val) {
      this.category_id = val
      this.doFilter()
    },
    doFilter() {
      const params = []
      if (this.userSelect === 'me') {
        params.push({
          column: 'author',
          value: this.userData.fullname,
        })
      }
      if (this.category_id !== '') {
        params.push({
          column: 'category',
          value: this.category_id,
        })
      }
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    checkTicket() {
      if (!this.desc) {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: 'Kolom Pertanyaan Tidak Boleh Kosong',
        }
      } else {
        this.$refs.newTicket.validate().then(success => {
          if (success) {
            this.saveTicket()
          }
        })
      }
    },
    saveTicket() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        category_id: this.category.helpdesk_category_id,
        subject: this.title,
        message: this.desc,
      }
      this.$http.post(`/helpdesk/add-ticket?token=${localStorage.getItem('userToken')}`, params)
        .then(res => {
          if (res.data.status === 'success') {
            document.getElementById('loading-bg').style.display = 'none'
            this.alert = false
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Tiket Berhasil Disimpan',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            }, 1500)

            setTimeout(() => {
              this.$refs['modal-tambah-tiket'].hide()
              this.loadItems()
            }, 500)
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
    tambahCat() {
      this.$refs['modal-tambah-cat'].show()
    },
    editCat(val) {
      this.cat = val
      this.$refs['modal-tambah-cat'].show()
    },
    checkCat() {
      this.$refs.newCat.validate().then(success => {
        if (success) {
          this.saveCat()
        }
      })
    },
    saveCat() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        nama: this.cat.nama,
        status: 1,
      }
      if (this.cat.helpdesk_category_id) {
        params.helpdesk_category_id = this.cat.helpdesk_category_id
      }
      this.$http.post(`/helpdesk/add-category?token=${localStorage.getItem('userToken')}`, params)
        .then(res => {
          if (res.data.status === 'success') {
            this.alert = false
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Kategori Berhasil Disimpan',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            }, 1500)

            setTimeout(() => {
              this.$refs['modal-tambah-cat'].hide()
              document.getElementById('loading-bg').style.display = 'none'
              this.category_id = ''
              this.getReference()
            }, 1000)
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
    getReference() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.get('/helpdesk/category', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            this.loadItems()
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
      this.$http
        .get('/helpdesk/statistik', {
          params: {
            token: localStorage.getItem('userToken'),
          },
        })
        .then(res => {
          this.stats = res.data.data
        })
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange() {
      this.updateParams({ start: (this.selectPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onSortChange() {
      this.updateParams({ start: 0 })
      this.updateParams({
        sort_by: [{
          asc_desc: this.order,
          column: 'modified_on',
        }, {
          asc_desc: 'desc',
          column: 'created_on',
        }],
      })
      this.loadItems()
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.rows = []
      this.updateParams({ keyword: this.keyword })
      this.$http.post(`/helpdesk/list?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          document.getElementById('loading-bg').style.display = 'none'
          if (res.data.status === 'success') {
            this.alert = false
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.rows = []
            this.totalRecords = 0
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-radius: 0;
      border-top: none !important;

      .ql-editor {
        max-height: 40rem;
        overflow-y: scroll;
      }
    }
  }
</style>
